import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { navigate } from "gatsby"


class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.onCloseWithRedirect = () => {
      this.props.onHide();
      navigate("/");
    }

    this.onCloseGoToStep = () => {
      this.props.onHide();
      this.props.jumpToStep(this.props.stepToRedirect)
    }

  }
  render() {
    const { show, onHide, title, body, showSaveButton, redirectToHome, redirectToStep} = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
        {!redirectToStep && !redirectToHome && (
          <Button variant="secondary" 
          onClick={onHide}>
            Fechar
          </Button>
          )}
          {redirectToStep && (
            <Button variant="primary" 
            onClick={this.onCloseGoToStep}>
              Fechar
            </Button>
          )}
          {redirectToHome && (
            <Button variant="primary" 
            onClick={this.onCloseWithRedirect}>
              Fechar
            </Button>
          )}
          {showSaveButton && (
            <Button variant="primary" 
            onClick={onHide}>
              Save Changes
            </Button>
          )}

        </Modal.Footer>
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  showSaveButton: PropTypes.bool,
  redirectToHome: PropTypes.bool,
  redirectToStep: PropTypes.bool,
  stepToRedirect: PropTypes.number
};

export default ModalComponent;
